.website-packages-page {
  max-width: 1200px;
  .in-packages-page {
    margin-bottom: 22px;
  }

  .table-package-img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #ddd;
    box-shadow: 0 0 4px rgba(#000, 0.3);
    display: grid;
    place-items: center;
    overflow: hidden;
    margin: auto;
    border: 2px solid transparent;

    img {
      height: calc(72px - 4px) !important;
      width: calc(72px - 4px) !important  ;
      object-fit: cover;
      border-radius: 50%;
      object-position: center center;
    }
  }
}
