@import '../../scss/config';

.influencer-form {
  .form-title {
    color: #40404199;
    padding: 10px 22px;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .back-btn {
      cursor: pointer;
      border: 0;
      outline: 0;
      background-color: #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      transition: all 0.2s ease-out;
      svg {
        width: 15px;
        height: 15px;
      }
      &:hover {
        background-color: #eee;
      }
    }
  }
  .form-body {
    margin: 8px 0;

    .code-input-wrapper {
      position: relative;
      .code-btn {
        background-color: #eee;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 0;
        outline: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 8px;
        top: 38px;
        cursor: pointer;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    .two-cols-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 22px;

      .main-col {
        padding: 22px 32px;
        background: #ffffff;
        box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
        border-radius: 20px;
      }

      .commision-wrapper {
        margin-bottom: 22px;
      }
      .commision-wrapper,
      .socials-wrapper {
        padding: 22px 32px;
        background: #ffffff;
        box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
        border-radius: 20px;
      }

      .links-label {
        margin-top: 22px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .links-wrapper {
        margin-top: 22px;
      }
    }
  }

  .client-password-field-wrap {
    position: relative;
    &.with-eye-icon {
      input {
        padding-left: 42px !important;
      }
    }
    .eye-icon-btn {
      position: absolute;
      left: 14px;
      top: 13px;
      cursor: pointer;
      padding: 4px;
      z-index: 1;
      img {
        height: 18px;
      }
    }
  }

  .form-action-btns-wrapper {
    margin-top: 18px;
    justify-content: end;
  }
}
