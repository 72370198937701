@import '../../scss/config';

.influencers-page {
  .page-main-content {
    .page-title {
      color: #40404199;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }

  .page-content-devices-wrapper {
    display: grid;
    align-content: start;
    grid-template-columns: 1fr;
    gap: 0;
  }

  .table-package-img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #ddd;
    box-shadow: 0 0 4px rgba(#000, 0.3);
    display: grid;
    place-items: center;
    overflow: hidden;
    margin: auto;
    border: 2px solid transparent;

    img {
      height: calc(72px - 4px) !important;
      width: calc(72px - 4px) !important  ;
      object-fit: cover;
      border-radius: 50%;
      object-position: center center;
    }
  }
  .package-tags {
    display: grid;
    gap: 8px;
    justify-content: center;

    .subscription-tags,
    .device-tags {
      display: flex;
      grid-gap: 8px;
      flex-wrap: wrap;
    }
  }

  .page-devices-wrapper {
    position: sticky;
    top: 32px;

    min-height: 65vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 2px 2px 12px #091a5814;
    // overflow: hidden;
    gap: 8px;
    @include mediaXXlg {
      gap: 22px;
    }
    .section-title {
      color: #40404199;
      font-weight: bold;
      border-bottom: 1px solid rgba(209, 211, 212, 0.2);
      display: flex;
      gap: 2px;
      button {
        padding: 10px 22px;
        width: 50%;
        border: 0;
        font-size: 14px;
        min-height: 48px;
        @include mediaXXlg {
          min-height: 54px;
        }
        cursor: pointer;
        &:first-of-type {
          border-top-right-radius: 12px;
        }
        &:last-of-type {
          border-top-left-radius: 12px;
        }

        &.active {
          background-color: #2a9d7c;
          color: #fff;
        }
      }
    }

    .empty-devices-wrapper {
      display: grid;
      place-items: center;
    }

    .devices-list-wrapper {
      padding-left: 2px;
      // height: 60vh;
      max-height: 63vh;
      overflow-y: auto;
      display: grid;
      align-content: start;
      @include custom-scrollbar;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: #e1e1e1;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #ccc;
        &:hover {
          background: #aaa;
        }
      }
      .device-item {
        display: flex;
        align-items: center;
        gap: 14px;
        border-bottom: 1px solid rgba(209, 211, 212, 0.2);
        padding: 10px;
        @include mediaXXlg {
          padding: 25px;
        }
        &:last-of-type {
          border-bottom: 0;
        }
        .device-img-wrapper {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          background-color: #eee;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 36px;
            height: 36px;
          }
          img {
            width: 32px;
            max-height: 42px;
            object-fit: cover;
            object-position: center center;
            border-radius: 2px;
          }
        }
        .device-details {
          .name {
            color: #404041;
            font-weight: bold;
            text-transform: capitalize;
            font-size: 18px;
            margin-bottom: 8px;
          }
          .model-p {
            color: #40404166;
          }
        }

        .item-btns-wrapper {
          display: flex;
          align-items: center;
          gap: 18px;
          justify-content: flex-start;
          margin-top: 10px;
          button {
            color: rgba(64, 64, 65, 0.6);
            border: 0;
            outline: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            transition: all 0.15s ease-out;

            svg {
              width: 18px;
              path {
                transition: all 0.15s ease-out;
              }
            }

            &:hover {
              color: #000;
              svg {
                path {
                  fill: #000;
                }
              }
            }
          }
        }
      }
    }

    .add-device-btn {
      justify-self: end;
      border: 0;
      outline: 0;
      border-radius: 50%;
      background-color: #2a9d7c;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      width: 38px;
      height: 38px;
      margin: 14px 18px;
      margin-top: 0;
      @include mediaXXlg {
        width: 56px;
        height: 56px;
        margin: 22px;
        margin-top: 0;
      }
      // &.with-device-form {
      //   background-color: red;
      // }
    }
    .add-brand-btn {
      justify-self: end;
      margin-top: 0;
      border: 0;
      outline: 0;
      border-radius: 50%;
      background-color: #2a9d7c;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 38px;
      height: 38px;
      margin: 14px 18px;

      @include mediaXXlg {
        width: 56px;
        height: 56px;
        margin: 22px;
      }
    }
  }
}
