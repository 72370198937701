@import '../../scss/config';

.stats-boxs-wrapper {
  margin-top: 42px;
  display: grid;
  @include mediaMd {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mediaXlg {
    grid-template-columns: repeat(4, 1fr);
  }
  gap: 22px;
  margin-bottom: 42px;
  .box-wrapper {
    display: flex;
    gap: 42px;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    height: 138px;
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);

    .box-value {
      color: $main-app-color;
      font-weight: bold;
      font-size: 20px;
    }
    .title-p {
      color: #404041;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .sub-title-p {
      color: #40404199;
    }
  }
}
