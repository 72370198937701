@import '../../scss/config';

.edit-profile-page {
  .profil-page-title {
    h3 {
      color: #404041;
      font-size: 22px;
      font-weight: bold;
    }
    h4 {
      margin-bottom: 22px;
      margin-top: 8px;
    }
  }
  .edit-profile-form {
    max-width: 732px;
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
    border-radius: 20px;
    padding: 32px 28px;
  }

  .change-pass-btn-wrapper {
    max-width: 732px;
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
    border-radius: 20px;
    padding: 32px 28px;
    width: 100%;
    margin-top: 28px;
    &.visible {
      button {
        background-color: lighten($main-app-color, 54%) !important;
        border-color: lighten($main-app-color, 40%);
      }
    }
    button {
      outline: 0;
      border: 1px solid rgba(#000, 0.2);
      border-radius: 999px;
      width: 100%;
      background-color: transparent;
      height: 59px;
      color: #404041;
      padding: 4px 32px;
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: space-between;
      cursor: pointer;
      transition: all 0.2s ease-out;
      font-weight: bold;
      &:hover {
        background-color: #f9f9f9;
      }
    }
  }

  .page-content-wrapper {
    display: grid;
    max-width: 1100px;
    gap: 22px;
    justify-content: start;
    grid-template-columns: 1fr;
    @include mediaXXlg {
      grid-template-columns: 2fr 1.4fr;
    }
  }

  .change-profile-password-form {
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
    border-radius: 20px;
    max-width: 732px;
    // @include mediaXXlg {
    //   margin-top: 70px;
    // }
    .form-body {
      padding: 32px 28px;
    }
    .form-title {
      padding: 18px 28px;
      border-bottom: 1px solid rgba(#000, 0.1);
    }
  }
}
