@import '../../scss/config';

.main-lang-btn {
  display: grid;
  place-items: center;
  .fi {
    display: block;
    // width: 38px;
    // height: 38px;
    width: 28px;
    height: 18px;
    background-color: #eee;
    border-radius: 2px;
    // border: 1px solid rgba(#fff, 0.5);
    // border-radius: 50%;
    cursor: pointer;
    background-size: cover;
    // @include mediaLg {
    //   width: 32px !important;
    //   height: 24px !important;
    //   cursor: pointer !important;
    // }
  }
}
