@import '../../scss/config';

.settings-page {
  .page-title {
    h3 {
      font-size: 28px;
      color: #404041;
      margin-bottom: 18px;
      font-weight: bold;
    }
    h4 {
      color: rgba(64, 64, 65, 0.6);
      margin-bottom: 22px;
    }
  }

  .row-wrapper {
    display: grid;
    gap: 12px;
    padding: 22px 12px;
    margin-bottom: 22px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
      gap: 18px 22px;
      padding: 22px;
    }
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
    border-radius: 20px;
  }

  .quill-wrapper {
    margin-bottom: 38px;
    @include mediaLg {
      margin-bottom: 8px;
    }
    height: 332px;
    @include mediaLg {
      height: 360px;
    }
    .ql-toolbar.ql-snow {
      border-radius: 12px 12px 0 0;
      direction: ltr;
    }
    .ql-container.ql-snow {
      border-radius: 0 0 12px 12px;
    }
    .snow-editor {
      height: 242px;
    }

    .card-title {
      margin-bottom: 16px;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}
