#recaptcha-container {
  display: none;
}
@import './config';
@import './common';
@import './modals';

html {
  scroll-behavior: smooth;
  color: $main-text-color;

  &[lang='ar'] {
    body {
      @include bodyFont-ar;
      button {
        @include bodyFont-ar;
      }
    }
  }
  &[lang='en'] {
    body {
      @include bodyFont-ar;
      button {
        @include bodyFont-ar;
      }
    }
  }

  // &[lang="en"] {
  // 	@include bodyFont-en;
  // 	button {
  // 		@include bodyFont-en;
  // 		font-weight: bold;
  // 	}
  // }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  width: 100% !important;
  @include custom-scrollbar;
  overflow-y: auto;
  background-color: #fff;
}

.app {
  min-height: 100vh;
  // background-color: #f5f5f5;
  // background-color: #f7f8fb;
  background-color: #fff;
  // display: grid;
  grid-template-rows: auto 1fr;

  // &.app-ltr {
  // 	@include bodyFont-en;
  // }

  margin: 0;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @import "./css/fontello.css";
  // @import "./css/ionicons.css";
}

[contenteditable='true'] {
  line-height: 1.8;
  // display: grid;
  // align-items: center;
}

.rnc__base {
  direction: rtl;
  &.rtl {
    direction: rtl;
    .rnc__notification-close-mark {
      right: auto;
      left: 10px;
    }
    .rnc__notification-timer-filler {
      @keyframes timer {
        0% {
          width: 0;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
}

// .ant-table-wrapper {
//   max-height: 90vh;
//   overflow-y: auto;
//   @include custom-scrollbar;
//   &::-webkit-scrollbar {
//     width: 4px;
//   }
//   &::-webkit-scrollbar-track {
//     border-radius: 4px;
//     background-color: #e1e1e1;
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 4px;
//     background-color: #ccc;
//     &:hover {
//       background: #aaa;
//     }
//   }
// }

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: center;
}
.ant-table-tbody > tr > td,
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: center;
}

.ant-table-thead > tr > th,
.ant-table-thead > tr > th.ant-table-column-sort {
  background-color: #595f6f;
  color: #fff;
  text-align: center;
  &::before {
    // background-color: #bbb !important;
    left: 0;
    right: auto !important;
    background-color: transparent !important;
  }
}
.antd-custom-table {
  table {
    border-spacing: 0 18px !important;
  }
  .ant-table-thead {
    & > tr > th {
      // background-color: $main-app-color;
      color: rgba(64, 64, 65, 0.707);
      border-bottom: 0;
      padding: 6px 10px !important;
      @include mediaXXlg {
        padding: 6px 16px !important;
      }
    }
    & > tr:first-child th:first-child {
      border-radius: 0 12px 12px 0;
    }
    & > tr:first-child th:last-child {
      border-radius: 12px 0 0 12px;
    }
  }
  tbody {
    & > tr {
      &:nth-of-type(n + 2) {
        box-shadow: 2px 2px 8px #091a5814;
        border-radius: 12px;
        & > td {
          border-bottom: 0;
          background-color: #fff;
          padding: 10px 12px !important;
          @include mediaXXlg {
            padding: 16px !important;
          }
          &:first-child {
            border-radius: 0 12px 12px 0;
          }
          &:last-child {
            border-radius: 12px 0 0 12px;
          }
        }
      }
    }
  }
  .has-read-more {
    color: #8b8b8b;
    font-size: 15px;
    display: flex;
    align-items: center;
    .read-more-span {
      padding-bottom: 4px;
    }
  }
  .ltr-text {
    direction: ltr;
  }

  .row-cell {
    // width: 132px;
    display: grid;
    justify-content: center;

    &.row-index {
      // width: 82px;
      span {
        color: $main-app-color;
        background-color: #fff;
        font-size: 16px;
        border-radius: 10px;
        padding: 8px 4px;
        // min-width: 52px;
        min-height: 48px;
        display: grid;
        place-items: center;
        justify-content: center;
      }
    }

    &.row-title {
      display: grid;
      .label-span {
        color: $main-app-color;
        text-align: start;
      }
    }
    &.row-text {
      color: #8b8b8b;
      text-align: center;
    }
    &.row-img {
      img {
        cursor: pointer;
        width: 132px;
        max-height: 92px;
        object-fit: cover;
        object-position: top center;
        border-radius: 4px;
      }
    }
    &.row-icon {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &.row-link {
      a {
        background-color: transparent;
        color: $main-app-color;
        border: 1px solid #000;
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 14px;
        transition: all 0.2s ease-out;
        &:hover {
          background-color: $main-app-color;
          color: #fff;
        }
      }
    }

    &.row-action-btn {
      .custom-btn {
        background-color: #b5b5b5;
        color: #222;
        padding: 8px 14px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        svg {
          width: 17px;
          height: 17px;
        }
      }
    }

    &.row-actions {
      // min-width: 262px;
      // padding: 18px 0 !important;
      .action-btns-wrap {
        display: flex;
        align-items: center;
        gap: 8px;
        &.two-rows {
          flex-direction: column;
          gap: 18px;
        }
          .main-btns-wrapper {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 12px;
            button,
            a {
              border: 0;
              outline: 0;
              background-color: rgba(209, 211, 212, 0.2);
              width: 48px;
              height: 48px;
              border-radius: 50%;
              display: grid;
              place-items: center;
              cursor: pointer;
            }
          }

          .accept-reject-btns {
            display: flex;
            justify-content: center;
            gap: 18px;
            button {
              border: 0;
              outline: 0;
              padding: 10px 22px;
              color: #fff;
              border-radius: 10px;
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              font-size: 13px;

              &.accept-btn {
                background-color: #2a9d7c;
              }
              &.reject-btn {
                background-color: #dc5259;
              }
              &.deactivate-btn {
                gap: 4px;
                background-color: rgba(64, 64, 65, 0.2);
                color: #404041;
                padding: 10px 8px !important;
                @include mediaXXlg {
                  padding: 10px 14px !important;
                }
              }
            }
          }

      }
      .reports-btn,
      .edit-btn {
        background-color: #b5b5b5;
        color: #222;
        padding: 7px 14px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .delete-btn {
        background-color: $main-app-color;
        padding: 10px 7px;
        border-radius: 12px;
        display: grid;
        place-items: center;
        cursor: pointer;
        svg {
          width: 18px;
          height: 14px;
        }
      }
    }

    &.row-circled-btn {
      .circled-btn {
        width: 40px;
        height: 40px;
        span {
          font-size: 18px;
        }
      }
    }
  }

  // sorting
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: $main-app-color !important;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover::before {
    background-color: #bbb !important;
  }
}

.ant-select-rtl {
  width: 100%;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}

.popover-answers-wrap {
  display: grid;
  grid-gap: 8px;
  list-style-type: disc;
  padding-right: 22px;
  max-width: 362px;
}

.ant-popover-inner-content {
  max-width: 280px;
}

.ant-avatar.ant-avatar-circle.ant-avatar-icon {
  line-height: 34px !important;
}

//
.notification__item--success {
  background-color: lighten($main-app-color, 10%);
  // border: 0;
  border-left-width: 5px;
  .notification__close {
    background-color: #fff;
    width: 22px;
    height: 22px;

    &::after {
      color: $main-app-color;
      font-size: 20px;
      top: 32%;
    }
  }
}
.notification__item--danger {
  background-color: lighten($main-app-color, 10%);
  // border: 0;
  border-left-width: 5px;
  .notification__title {
    color: #fff;
  }
  .notification__close {
    background-color: #fff;
    width: 22px;
    height: 22px;

    &::after {
      color: #dc3545;
      font-size: 20px;
      top: 32%;
    }
  }
}

.invoice-details-wrap {
  td,
  th {
    padding: 2px !important;
    font-size: 14px;
  }
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #ddd;
}
.ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-row,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered > .ant-table-container {
  border-color: #ddd !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  background-color: #eee !important;
  -webkit-print-color-adjust: exact;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa !important;
  -webkit-print-color-adjust: exact;
}

.ant-table-thead > tr > th {
  background-color: transparent;
  color: #000;
}

.ant-descriptions-item-content,
.ant-descriptions-item-label {
  padding: 4px 12px !important;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #ddd;
}
.ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-row {
  border-color: #ddd !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered > .ant-table-container {
  border-color: #888 !important;
}

.ant-table-thead > tr > th {
  background-color: transparent;
  color: #000;
}

.ant-notification-notice-with-icon {
  cursor: pointer;
}

.filter-stats-search-wrapper {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  min-height: 78px;
  margin-bottom: 2px;
  // @include mediaXlg {
  //   grid-template-columns: auto 1fr;
  // }
  // @include mediaXXlg {
  //   grid-template-columns: auto 1fr auto;
  // }

  .stats-new-btn-wrapper {
    width: max-content;
    background: rgba(209, 211, 212, 0.1);
    border: 1px solid rgba(209, 211, 212, 0.2);
    border-radius: 20px;
    display: flex;
    padding: 12px;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;

    @include mediaXXlg {
      gap: 52px;
      padding: 12px 22px;
    }

    .stats-wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .stat-box {
        display: flex;
        gap: 3px;
        align-items: center;
        position: relative;
        padding-left: 8px;
        margin-left: 8px;
        @include mediaXXlg {
          gap: 10px;
          padding-left: 18px;
          margin-left: 18px;
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          width: 1px;
          height: 80%;
          top: 10%;
          background-color: #ccc;
        }
        &:last-of-type {
          padding-left: 0;
          margin-left: 0;
          &::after {
            display: none;
          }
        }
        .label-span {
          font-size: 13px;
          @include mediaXXlg {
            font-size: 14px;
          }
        }

        .ant-badge {
          font-size: 13px;
          @include mediaXXlg {
            font-size: 14px;
          }

          .ant-badge-count {
            background-color: $main-app-color;
            padding: 4px 9px;
            height: 22px;
            line-height: 12px;
            font-size: 13px;
            border-radius: 9999px;

            @include mediaXXlg {
              padding: 8px 15px;
              height: 38px;
              line-height: 22px;
              font-size: 16px;
              border-radius: 9999px;
            }
          }
        }
        .value-span {
          background-color: $main-app-color;
          color: #fff;
        }
      }
    }
  }

  .reset-btn {
    margin-right: auto;
    cursor: pointer;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    border: 0;
    outline: 0;
    background-color: rgba(209, 211, 212, 0.4);

    svg {
      fill: #404041b1;
    }
  }
}

.ant-select-dropdown {
  border-radius: 14px !important;
  overflow: hidden;
  .ant-select-item {
    padding: 8px 18px;
  }
}

.shared-form-password-field-wrap {
  position: relative;
  &.with-eye-icon {
    input {
      padding-left: 42px !important;
    }
  }
  .eye-icon-btn {
    position: absolute;
    left: 14px;
    top: 13px;
    cursor: pointer;
    padding: 4px;
    z-index: 1;
    img {
      height: 18px;
    }
  }
}
