.invoice-modal-wrapper {
  background-color: rgba(#000, 0.8) !important;
}
.invoice-modal {
  .ant-modal-content,
  .ant-modal-body {
    height: 100%;
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    .ant-modal-close .anticon svg {
      color: #fff !important;
    }

    .ant-modal-close-x .anticon {
      border-color: #fff !important;
    }

    .ant-modal-close {
      position: fixed !important;
      top: 1% !important;
      right: 1% !important;
    }
  }
  .ant-modal-content {
    padding-top: 22px !important;
  }
}
