.brand-form {
  border: 1px solid rgba(209, 211, 212, 0.6);
  border-radius: 12px;
  box-shadow: 2px 2px 12px #091a5814;
  .form-title {
    color: #40404199;
    padding: 10px 22px;
    padding-left: 16px;
    border-bottom: 1px solid rgba(209, 211, 212, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }
}
