@import '../../../scss/config';

.profile-dropdown-btn-wrapper {
  .profile-menu-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    height: fit-content;
    padding: 0;
  }
}

.main-app-profile-dropdown {
  min-width: 252px !important;
  border-radius: 18px;

  .ant-dropdown-menu {
    padding-bottom: 0;
    padding-top: 0;
    border-radius: 18px;
  }

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  .profile-link {
    border-radius: 18px 18px 0 0;
    padding: 10px 22px;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    gap: 12px;
    background-image: url('../../../assets/imgs/profile-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ededee99;
    .profile-img {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      overflow: hidden;
      svg {
        width: 98%;
        height: 98%;
      }
      img {
        width: 42px;
        height: 42px;
        border: 2px solid $main-app-color;
        border-radius: 50%;
      }
    }
    .name-email-country-wrapper {
      color: #404041;
      p {
        text-align: start;
      }
      .name-p {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 2px;
      }
      .email-p {
        color: #40404166;
        margin-bottom: 2px;
      }
      .location-p {
        display: flex;
        align-items: center;
        gap: 4px;
        svg {
          width: 18px;
        }
      }
    }
  }
  .profile-label {
    color: $main-app-color;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
    padding: 10px 22px !important;
    cursor: default;
  }

  .menu-link {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    padding: 10px 22px;

    svg {
      width: 24px;
      height: 24px;
      color: #6d6d6d;
    }
  }
  .signout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 16px;
    text-align: center;
    padding: 22px 22px !important;
    background-color: #ededee99;

    .ant-dropdown-menu-title-content {
      flex: unset;
    }
  }
}
