@import '../../scss/config';

.signin-form {
  width: 100%;
  display: grid;
  height: max-content;
  border-radius: 10px;
  align-self: center;
  padding: 18px 16px;
  @include mediaLg {
    padding: 22px 32px;
    max-width: 442px;
  }
  @include mediaXlg {
    max-width: 532px;
  }
  .form-header {
    @include mediaLg {
      margin-bottom: 32px;
    }
    .form-logo {
      display: grid;
      padding: 32px;
      padding-bottom: 8px;
      place-items: center;
      img {
        max-width: 100%;
        height: 52px;
      }
    }
    p {
      color: #fff;
      margin-bottom: 18px;
      font-size: 22px;
      text-align: center;

      @include mediaLg {
        font-size: 28px;
        text-align: start;
        max-width: 80%;
      }

      &.main-title {
        color: $main-app-color;
        font-size: 32px;
        margin-bottom: 12px;

        @include mediaLg {
          font-size: 40px;
        }
      }
    }
  }

  .form-body {
    display: grid;
    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper:hover {
      border-radius: 8px;
      height: 60px;
      background-color: #fff;
      border: 0;
      box-shadow: none;
    }
    &.form-body .form-text-field {
      background-color: #fff !important;
      color: #404041 !important;
    }

    .shared-app-text-field-label-wrapper {
      .outside-label {
        color: #fff !important;
      }
    }

    .ant-checkbox-wrapper {
      color: #777;
      font-size: 16px;
    }

    .login-password-field-wrap {
      position: relative;
      &.with-eye-icon {
        input {
          padding-left: 42px !important;
        }
      }
      .eye-icon-btn {
        position: absolute;
        left: 14px;
        top: 13px;
        cursor: pointer;
        padding: 4px;
        z-index: 1;
        img {
          height: 18px;
        }
      }
    }

    .remember-checkbox {
      margin-bottom: 0;
      @include mediaLg {
        margin-bottom: 22px;
      }
      .ant-checkbox-inner {
        width: 22px;
        height: 22px;
        border-radius: 5px;
        background-color: transparent;
      }
      .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item
        input[type='checkbox'] {
        width: 22px;
        height: 22px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $main-app-color;
      }
      .ant-checkbox-inner::after {
        left: 28%;
      }
      .ant-checkbox + span {
        color: #fff !important;
      }
    }

    .submit-btn {
      margin-top: 12px;
      cursor: pointer;
      outline: 0;
      border: 0;
      color: #fff;
      padding: 8px 62px;
      height: 50px;
      border-radius: 999px;
      background-image: linear-gradient(180deg, #2a9d7c 0%, #287c64 100%);
      justify-self: stretch;
      height: 52px;

      @include mediaLg {
        height: 64px;
      }
    }
  }
  &.custom-shared-form {
    .form-body {
      .form-text-field {
        border: 1px solid rgba(#fff, 0.5) !important;
        color: #fff;
      }
    }
  }
  .forget-p {
    text-decoration: underline;
    cursor: pointer;
    color: $main-app-color;
    margin-top: -12px;
    margin-bottom: 12px;
  }
}
