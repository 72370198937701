.profile-admins-table-wrapper {
  margin-top: 52px;
  .table-title {
    color: rgba(64, 64, 65, 0.6);
    font-size: 18px;
    margin-bottom: 18px;
    font-weight: bold;
  }

  .profile-admins-table {
    .table-package-img {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: #ddd;
      box-shadow: 0 0 4px rgba(#000, 0.3);
      display: grid;
      place-items: center;
      overflow: hidden;
      margin: auto;
      border: 2px solid transparent;

      img {
        height: calc(72px - 4px) !important;
        width: calc(72px - 4px) !important  ;
        object-fit: cover;
        border-radius: 50%;
        object-position: center center;
      }
    }
    .package-tags {
      display: grid;
      gap: 8px;
      justify-content: center;

      .subscription-tags,
      .device-tags {
        display: flex;
        grid-gap: 8px;
        flex-wrap: wrap;
      }
    }
  }
}
