.custom-image {
  // background-color: #eee;
  // border-radius: 5px;
  display: grid;
  width: 100%;
  svg {
    width: 60%;
    height: 60%;
  }
}
