.common-tag-wrap {
  background-color: #eee;
  border-radius: 10px;
  color: #404041;
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  padding: 8px 14px;
  padding-bottom: 9px;

  &.green {
    background-color: rgba(42, 157, 124, 0.2);
  }
  &.yellow {
    background-color: rgba(238, 159, 45, 0.2);
  }
  &.red {
    background-color: rgb(255, 220, 220);
  }
}
