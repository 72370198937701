@import '../../scss/config';

.influencer-info-stats-sections {
  .stats-section {
    display: grid;
    grid-template-columns: 232px 1fr;
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
    border-radius: 20px;
    overflow: hidden;
    .profile-img {
      background-color: #ededee;
      display: grid;
      place-items: center;
      img,
      svg {
        width: 100%;
      }
    }

    .stats-wrapper {
      padding: 8px 18px;
      display: grid;
      align-content: center;

      .name {
        color: #404041;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 14px;
      }
      .email {
        color: hsla(240, 1%, 25%, 0.605);
      }
    }

    .inf-stats-boxs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 14px 24px;
      margin-top: 18px;
      .box-stat {
        display: flex;
        width: fit-content;
        align-items: center;
        gap: 8px;
        .value-wrap {
          color: $main-app-color;
          font-size: 20px;
          font-weight: bold;
        }
        .title-wrap {
          font-size: 15px;
        }
      }

      .code-wrap {
        color: rgba(64, 64, 65, 0.6);
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  // .info-note-wrapper {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   gap: 22px;
  // }
  .profile-info-section {
    align-self: start;
    min-height: 300px;
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
    border-radius: 20px;
    padding: 22px 32px;

    h4 {
      color: rgba(64, 64, 65, 0.6);
      font-size: 18px;
      margin-bottom: 18px;
    }
    .infos-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      justify-content: space-between;
      gap: 22px 12px;
    }
    .single-info-box {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .box-stat {
      display: flex;
      width: fit-content;
      align-items: center;
      gap: 8px;
      .value-wrap {
        color: $main-app-color;
        font-size: 20px;
        font-weight: bold;
      }
      .title-wrap {
        font-size: 15px;
      }
    }
  }
  .note-wrap {
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
    border-radius: 20px;
    margin-top: 22px;
    padding: 22px 32px;
    h4 {
      color: rgba(64, 64, 65, 0.6);
      font-size: 18px;
      margin-bottom: 18px;
    }
  }
}
