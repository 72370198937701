@import '../../scss/config';

.website-contacts-page {
  .time {
    color: gray;
  }
  .page-title {
    font-size: 18px;
    color: rgba(64, 64, 65, 0.6);
    margin-bottom: 8px;
  }
  .website-title {
    font-size: 22px;
    color: #404041;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .page-content {
    .page-list-selected-wrap {
      display: grid;
      @include mediaLg {
        grid-template-columns: 1fr 1fr;
      }
      gap: 22px;
      max-width: 1262px;
      background: #ffffff;
      box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
      border-radius: 20px;
      overflow: hidden;
    }

    .page-list {
      margin: 8px 0;
      border-left: 1px solid rgba(#000, 0.1);
      // max-height: 70vh;
      max-height: calc(100vh - 14px - 146px - 32px);
      overflow-y: auto;
      @include custom-scrollbar;
      display: grid;
      align-content: start;
      .contact-card {
        text-align: start;
        border: 0;
        outline: 0;
        background-color: transparent;
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 12px;
        align-items: center;
        padding: 22px;
        margin: 2px 8px;
        border-radius: 8px;
        cursor: pointer;
        background-color: #f7f8fa;
        transition: all 0.2s ease-out;
        &:hover,
        &.selected {
          background: rgba(42, 157, 124, 0.12);
          .name-p {
            color: $main-app-color;
          }
        }

        .remove-btn {
          border: 0;
          outline: 0;
          align-self: start;
          background-color: transparent;
          cursor: pointer;
          width: 32px;
          height: 32px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .initials {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(64, 64, 65, 0.6);
      background-color: #eee;
      border-radius: 50%;
      font-size: 26px;
      font-weight: bold;
    }

    .name-p {
      color: #404041;
      font-size: 18px;
      font-weight: bold;
    }
    .email-p {
      color: rgba(64, 64, 65, 0.6);
      margin-bottom: 4px;
    }

    .selected-item {
      max-height: calc(100vh - 14px - 146px - 32px);
      overflow-y: auto;
      @include custom-scrollbar;
      margin: 12px 0;
      margin-left: 8px;
      padding: 2px 12px;

      .email-header {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 12px;
        align-items: center;
        padding-bottom: 18px;
        margin-bottom: 18px;
        border-bottom: 1px solid rgba(#000, 0.1);
        position: relative;
        .time {
          display: flex;
          gap: 0.5rem;
          position: absolute;
          bottom: 1rem;
          left: 1rem;
        }
      }

      .email-body {
        color: #404041;
        line-height: 1.6;
      }

      .view-p {
        color: rgba(64, 64, 65, 0.6);
        font-size: 16px;
        margin-bottom: 24px;
      }
    }
  }
}
