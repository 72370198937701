.custom-empty-comp {
  display: grid;
  place-content: center;
  color: #40404166;
  text-align: center;
  p {
    margin-top: 22px;
  }

  img {
    max-width: 60%;
    margin: auto;
  }
}
