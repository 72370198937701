@import '../../scss/config';

.login-page {
  background-color: #373737;
  display: grid;
  min-height: 100vh;

  .page-wrapper {
    display: grid;
    @include mediaLLg {
      grid-template-columns: 42% 1fr;
    }

    .img-wrap {
      order: -1;
      @include mediaLg {
        order: 0;
      }
      background-color: #404041;
      display: grid;
      position: relative;

      .custom-container {
        display: flex;
        z-index: 1;
        position: relative;
        justify-content: center;
        @include mediaLg {
          justify-content: flex-end;
        }
        .extra-logo-img {
          margin-top: 62px;
          margin-bottom: 62px;
          display: block;
          width: fit-content;
          height: fit-content;
          pointer-events: none;
          img {
            width: 152px;
            @include mediaLg {
              width: 332px;
            }
          }
        }
        @include mediaLLg {
          padding: 64px;
          margin-left: 42px;
          margin-bottom: 0;
        }
      }
      .main-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include mediaLg {
          object-fit: contain;
        }
      }
      .img-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #3b3b3c;
        mix-blend-mode: soft-light;
      }
    }

    .form-top-level-wrapper {
      display: grid;
      justify-items: center;
      padding: 22px;
    }
  }
}
