@import '../../scss/config';

.custom-not-found-page {
  // background-color: #fff;
  min-height: 100vh;
  display: grid;
  place-items: center;
  .error-wrapper {
    // border: 2px dashed #eee;
    // background-color: #fdf9f8;
    // border: 2px dashed #eee5e3;
    // background-color: #fdf9f8;
    margin-bottom: 18px;
    padding: 12px 22px;
    // @include mediaMd {
    //   padding: 62px;
    // }
    border-radius: 4px;
    display: grid;
    justify-items: center;
    .err-img {
      display: flex;
      justify-content: center;
    }
    img {
      // max-width: 100%;
      max-width: 54%;
      @include mediaXXlg {
        max-width: 70%;
      }
    }

    .err-text {
      text-align: center;

      h1 {
        // margin-top: 12px;
        // margin-bottom: 12px;
        color: #000;
        text-transform: capitalize;
      }
      p {
        text-transform: capitalize;
        margin-bottom: 5px;
        color: #888;
      }
    }
    .home-btn {
      margin-top: 14px;
      display: flex;
      gap: 8px;
      align-items: center;
      color: #0e98b2;
      background-color: #c3ebf1;
      border: 0;
      outline: 0;
      border-radius: 999px;
      padding: 10px 18px;
      cursor: pointer;
      text-transform: capitalize;
      transition: all 0.2s ease-out;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      &:hover {
        background-color: #0e98b2;
        color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
