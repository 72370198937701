.website-settings-page {
  .page-title {
    font-size: 18px;
    color: rgba(64, 64, 65, 0.6);
    margin-bottom: 8px;
  }
  .website-title {
    font-size: 22px;
    color: #404041;
    margin-bottom: 8px;
    font-weight: bold;
  }

  form {
    max-width: 772px;
  }
  padding-bottom: 32px;
}
