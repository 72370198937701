@import '../../scss/config';

.md-drawer {   background-color: #404041 !important;
  .ant-drawer-content-wrapper {
    max-width: 242px;
    background-color: transparent;
    box-shadow: none !important;
   
    .ant-drawer-wrapper-body {
      padding-left: 5px;   
    }

    .ant-drawer-content {
     
      // border-top-left-radius: 52px;
      // border-bottom-left-radius: 52px;
      box-shadow: none;
    }

    .ant-drawer-header {
      border-bottom: 0;
    }

    .ant-drawer-body {
      overflow-y: auto;
      @include custom-scrollbar;
      margin-bottom: 42px;
      &::-webkit-scrollbar {
        width: 6px;
      }
    }
    .md-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 140px;
      }
    }
  }
  .app-bar-ul {
    align-self: center;
    display: grid;
    align-content: start;
    gap: 22px;
    // justify-items: center;

    .link-li {
      display: grid;
      color: rgba(#fff, 0.6);
      padding: 0 8px;
      position: relative;

      .nav-link {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #b5b5b5;
        justify-items: center;
        text-align: center;
        cursor: pointer;
        svg {
          width: 26px;
          height: 26px;
        }
        &.active-link {
          color: #fff;
        }
      }

      // .sub-menu-transition-group-enter {
      //   opacity: 0;
      //   transform: scale(0.9);
      // }
      // .sub-menu-transition-group-enter-active {
      //   opacity: 1;
      //   transform: translateX(0);
      //   transition: opacity 300ms, transform 300ms;
      // }
      // .sub-menu-transition-group-exit {
      //   opacity: 1;
      // }
      // .sub-menu-transition-group-exit-active {
      //   opacity: 0;
      //   transform: scale(0.9);
      //   transition: opacity 300ms, transform 300ms;
      // }
      .sub-menu-transition-group-enter {
        opacity: 0;
        transform: scale(0.96) translateY(52px);
      }
      .sub-menu-transition-group-enter-active {
        opacity: 1;
        transform: scale(1) translateY(0);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: ease-out;
      }
      .sub-menu-transition-group-exit {
        opacity: 1;
      }
      .sub-menu-transition-group-exit-active {
        opacity: 0;
        transform: scale(0.96);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: ease-out;
      }

      .sub-menu-ul {
        background-color: $main-app-color;
        // border-radius: 8px 0 0 8px;
        position: absolute;
        top: 0;
        right: 100%;
        // width: 172px;
        min-width: 152px;
        width: max-content;
        padding: 8px;
        z-index: 10;
        display: grid;
        gap: 8px;

        .sub-menu-li {
          text-align: center;
          display: grid;
          a {
            display: grid;
            color: rgba(#fff, 0.6);
            padding: 8px 18px;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
