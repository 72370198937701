@import '../../scss/config';

.website-experience-form {
  border: 1px solid rgba(209, 211, 212, 0.6);
  border-radius: 12px;
  // box-shadow: 2px 2px 12px #091a5814;
  .form-title {
    color: #40404199;
    padding: 10px 22px;
    padding-left: 16px;
    border-bottom: 1px solid rgba(209, 211, 212, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    .back-btn {
      cursor: pointer;
      border: 0;
      outline: 0;
      background-color: #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      transition: all 0.2s ease-out;
      svg {
        width: 10px;
        height: 10px;
      }
      &:hover {
        background-color: #eee;
      }
    }
  }
  .form-body {
    margin: 8px 0;
    padding: 10px 12px;
    @include mediaLg {
      padding: 10px 22px;
    }

    .modal-action-btns-wrapper {
      justify-content: flex-end;
    }
  }
}
