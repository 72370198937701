@import '../../scss/config';

.admin-form-page {
  .page-title {
    color: #404041;
    font-size: 24px;
    font-weight: bold;
    margin: 22px 0;
  }

  .form-title {
    color: rgba(64, 64, 65, 0.6);
    margin: 18px 0;
    font-size: 18px;
    font-weight: bold;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .back-btn {
      cursor: pointer;
      border: 0;
      outline: 0;
      background-color: #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      transition: all 0.2s ease-out;
      svg {
        width: 15px;
        height: 15px;
      }
      &:hover {
        background-color: #eee;
      }
    }
  }

  .admin-form-wrapper {
    form {
      background: #ffffff;
      box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
      border-radius: 20px;
      padding: 32px 28px;

      .admins-roles {
        padding: 12px;
        border-radius: 12px;
        border: 1px solid rgba(#000, 0.1);
        .ant-form-item-label {
          label {
            font-weight: bold;
          }
          margin-bottom: 8px;
        }
      }
      .roles-checkboxs {
        .ant-checkbox-group {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 18px 8px;
        }
      }

      .modal-action-btns-wrapper {
        // border-top: 1px solid rgba(#000, 0.1);
        margin-top: 22px;
        padding-top: 22px;
        justify-content: end;
      }
    }
  }
}
