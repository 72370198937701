@import '../../scss/config';

.main-app-bar-wrap {
  display: none;
  @include mediaLg {
    display: block;
  }
  min-width: 124px;
  min-height: 94vh;
  align-self: start;
  background: #404041;
  border-radius: 25px 0px 0px 25px;

  box-shadow: -6px 8px 10px rgba(#000, 0.16);
  position: fixed;
  right: 0;
  top: 32px;
  z-index: 2;
  bottom: 32px;

  .side-menu-logo {
    display: grid;
    place-items: center;
    margin-bottom: 22px;
    img {
      height: 38px;
      @include mediaXXlg {
        height: 45px;
      }
    }
  }

  .app-bar-ul {
    display: grid;
    align-content: start;
    grid-gap: 22px;
    align-content: start;
    // justify-items: center;

    .link-li {
      display: grid;
      color: rgba(#fff, 0.6);
      padding: 0 8px;
      position: relative;

      .nav-link {
        color: #fff;
        font-size: 14px;
        gap: 8px;

        @include mediaXXlg {
          font-size: 16px;
          gap: 14px;
        }
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        svg {
          width: 22px;
          height: 22px;
          @include mediaXXlg {
            width: 26px;
            height: 26px;
          }
        }
        .name-span {
          &.separator {
            @include headingFont-ar;
            color: rgba(209, 211, 212, 0.4);
          }
        }
        &.active-link {
          color: $main-app-color;
        }
      }

      // .sub-menu-transition-group-enter {
      //   opacity: 0;
      //   transform: scale(0.9);
      // }
      // .sub-menu-transition-group-enter-active {
      //   opacity: 1;
      //   transform: translateX(0);
      //   transition: opacity 300ms, transform 300ms;
      // }
      // .sub-menu-transition-group-exit {
      //   opacity: 1;
      // }
      // .sub-menu-transition-group-exit-active {
      //   opacity: 0;
      //   transform: scale(0.9);
      //   transition: opacity 300ms, transform 300ms;
      // }
      .sub-menu-transition-group-enter {
        opacity: 0;
        transform: scale(0.96) translateY(52px);
      }
      .sub-menu-transition-group-enter-active {
        opacity: 1;
        transform: scale(1) translateY(0);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: ease-out;
      }
      .sub-menu-transition-group-exit {
        opacity: 1;
      }
      .sub-menu-transition-group-exit-active {
        opacity: 0;
        transform: scale(0.96);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: ease-out;
      }

      .sub-menu-ul {
        background-color: $main-app-color;
        border-radius: 8px 0 0 8px;
        position: absolute;
        top: 0;
        right: 100%;
        // width: 172px;
        min-width: 152px;
        width: max-content;
        padding: 8px;
        z-index: 10;
        display: grid;
        gap: 8px;

        .sub-menu-li {
          text-align: center;
          display: grid;
          a {
            display: grid;
            color: rgba(#fff, 0.6);
            padding: 8px 18px;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .settings-signout-wrap {
    // margin-top: 52px;
    cursor: pointer;
    display: grid;
    justify-items: center;
    grid-gap: 32px;
    .nav-link {
      display: grid;
      color: #b5b5b5;
      justify-items: center;

      &.active-link {
        color: #fff;
      }
    }
  }
}
