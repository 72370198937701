@import '../../scss/config';

.dropdown-filter-popup {
  border-radius: 12px;
  .ant-select-item {
    padding: 10px 12px;
  }
}

.sort-by-wrapper {
  width: fit-content;
}

.sort-by-select-filter {
  border-radius: 8px;
  min-width: 162px;

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    line-height: 74px !important;
    padding-right: 32px !important;
    text-align: center;
  }

  .ant-select-selector {
    height: 78px !important;
    border: 1px solid rgba(209, 211, 212, 0.2) !important;
    background: #d1d3d41a !important;
    padding: 0 20px !important;
    border-radius: 20px !important;

    &::after {
      line-height: 74px !important;
    }
  }

  .ant-select-arrow {
    right: 15px !important;
  }
}
