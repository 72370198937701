@import '../../scss/config';

.profile-info-stats-sections {
  .stats-section {
    display: grid;
    @include mediaLg {
      grid-template-columns: 232px 1fr;
    }
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
    border-radius: 20px;
    .profile-img {
      display: grid;
      place-items: center;

      img,
      svg {
        width: 160px;
      }
      @include mediaLg {
        background-color: #ededee;
        img,
        svg {
          width: 100%;
        }
      }
    }

    .stats-wrapper {
      padding: 8px 18px;
      display: grid;
      align-content: center;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 12px;

      .name {
        color: #404041;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 14px;
      }
      .email {
        color: hsla(240, 1%, 25%, 0.605);
      }
    }

    .edit-link {
      background-color: #eee;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 26px;
        height: 26px;
      }
    }

    .stats-boxs {
      display: grid;
      margin-top: 18px;
      @include mediaLg {
        grid-template-columns: repeat(3, 1fr);
      }
      .box-stat {
        display: flex;
        width: fit-content;
        align-items: center;
        gap: 8px;
        .value-wrap {
          color: $main-app-color;
          font-size: 20px;
          font-weight: bold;
        }
        .title-wrap {
          font-size: 15px;
        }
      }
    }
  }

  .profile-info-section {
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(9, 26, 88, 0.08);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 22px;
    padding: 22px 32px;

    h4 {
      color: rgba(64, 64, 65, 0.6);
      font-size: 18px;
      margin-bottom: 18px;
    }
    .infos-wrapper {
      display: grid;
      gap: 12px;
      @include mediaLg {
        gap: 22px 12px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
    .single-info-box {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}
