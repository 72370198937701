@import '../../scss/config';

.shared-create-new-btn {
  border-radius: 99px;
  outline: 0 !important;
  border: 1px solid #2a9d7c;
  background-color: transparent;
  padding: 8px 12px;
  gap: 3px;
  font-size: 13px;
  height: 38px;
  @include mediaXXlg {
    gap: 6px;
    padding: 10px 22px;
    font-size: 14px;
    height: 48px;
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #2a9d7c;
  transition: all 0.2s ease-out;
  &:hover {
    background-color: lighten(#2a9d7c, 58%);
    box-shadow: 0 2px 4px rgba(#000, 0.1);
  }

  svg {
    width: 18px;
    height: 18px;
    @include mediaXXlg {
      width: 22px;
      height: 22px;
    }
  }
}
