@import '../../scss/config';

.single-client-page {
  .subscription-invoices-wrapper {
    margin-top: 22px;
    display: grid;
    grid-template-columns: 55% 1fr;
    gap: 22px;
  }

  .invoices-wrapper {
    min-height: 65vh;
    display: grid;
    grid-template-rows: auto 1fr;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 2px 2px 12px #091a5814;

    .sec-title {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 14px 22px;
      color: rgba(64, 64, 65, 0.6);
      font-weight: bold;
    }
    .list-item-wrapper {
      padding: 22px;
      border-bottom: 1px solid rgba(#000, 0.1);
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: space-between;
      .date {
        color: rgba(64, 64, 65, 0.4);
      }
      .main-c {
        align-items: center;
        gap: 8px;
        display: flex;
      }

      .img-wrap {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: #f7f8fa;
        display: grid;
        place-items: center;
        grid-template-columns: 1fr auto;
      }
      .item-content {
        .label {
          color: #404041;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 4px;
        }
        .price {
          color: rgba(64, 64, 65, 0.6);
        }
      }
      .invoice-deatils-btn {
        border: 0;
        outline: 0;
        color: $main-app-color;
        font-size: 13px;
        padding: 6px 22px;
        padding-bottom: 8px;
        border-radius: 18px;
        background-color: lighten($main-app-color, 47%);
        cursor: pointer;
      }
    }
  }
}
