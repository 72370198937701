@import '../../../scss/config';

.top-app-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  margin-bottom: 8px;

  .menu-btn {
    display: flex;
    align-items: center;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-size: 22px;
    cursor: pointer;
    @include mediaLg {
      display: none;
    }
  }

  .date-welcome-wrapper {
    .date-p {
      color: #404041c4;
    }
    .welcome-p {
      color: #404041;
      font-weight: bold;
    }
  }

  .notifications-msgs-profile-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .msgs-link {
      display: none;
      @include mediaLg {
        display: flex;
      }
      background-color: rgba(209, 211, 212, 0.4);
      color: #404041;
      align-items: center;
      gap: 6px;
      border-radius: 99px;
      padding: 10px 28px;
    }
  }

  .notification-btn-wrap {
    display: flex;
  }
}
