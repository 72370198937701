.device-description-wrapper {
  min-height: 65vh;
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 2px 2px 12px #091a5814;

  .wrapper-title {
    border-bottom: 1px solid rgba(#000, 0.1);
    padding: 14px 22px;
    color: rgba(64, 64, 65, 0.6);
  }

  .content-wrapper {
    padding: 0 22px;
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    .brand-label {
      background-color: rgb(255, 235, 207);
      padding: 12px 42px;
      border-radius: 0 0 22px 22px;
      box-shadow: 2px 2px 12px rgba(#091a58, 0.08);
      width: fit-content;
      color: #404041;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      gap: 5px;
      align-items: center;
      margin: auto;
      margin-top: -1px;
      img {
        width: 18px;
        height: 18px;
      }
    }

    .name-label {
      text-align: center;
      margin: 18px 0;
      color: #2a9d7c;
      font-size: 18px;
    }

    .desc-wrapper {
      margin-top: 12px;
      margin-bottom: 22px;
    }
    .desc-label,
    .papers-label {
      color: #2a9d7c;
      font-size: 16px;
      margin-bottom: 8px;
      font-weight: bold;
    }

    .device-img {
      display: flex;
      justify-content: center;
      img,
      svg {
        height: 112px;
      }
      svg {
        width: 112px;
      }
    }
  }

  .papers-wrapper {
    margin-bottom: 32px;
    .list-wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      padding-top: 8px;
    }
  }
}
