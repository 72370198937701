@import '../../scss/config';

.package-form {
  border: 1px solid rgba(209, 211, 212, 0.6);
  border-radius: 12px;
  box-shadow: 2px 2px 12px #091a5814;
  .form-title {
    color: #40404199;
    padding: 10px 22px;
    padding-left: 16px;
    border-bottom: 1px solid rgba(209, 211, 212, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    .back-btn {
      cursor: pointer;
      border: 0;
      outline: 0;
      background-color: #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      transition: all 0.2s ease-out;
      svg {
        width: 10px;
        height: 10px;
      }
      &:hover {
        background-color: #eee;
      }
    }
  }
  .form-body {
    margin: 8px 0;
    padding: 10px 12px;
    @include mediaLg {
      padding: 10px 22px;
    }
    // height: 60vh;
    // max-height: 60vh;
    // overflow-y: auto;
    // display: grid;
    // align-content: start;
    // @include custom-scrollbar;
    // &::-webkit-scrollbar {
    //   width: 4px;
    // }
    // &::-webkit-scrollbar-track {
    //   border-radius: 4px;
    //   background-color: #e1e1e1;
    // }
    // &::-webkit-scrollbar-thumb {
    //   border-radius: 4px;
    //   background-color: #ccc;
    //   &:hover {
    //     background: #aaa;
    //   }
    // }
    .feature-inputs {
      .feature-input-group {
        display: flex;
        align-items: center;
        gap: 1rem;  // Spacing between input fields and delete button
        padding: 0.5rem;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        // background-color: #e0e0e0;
        margin-bottom: 1rem;
        
        .two-cols-row {
       align-items: center;
          flex: 1;  // Allows the input fields to take up available space
          .ant-form-item {
            margin: 0;
          }
        }
    
        /* Remove button styling */
        button[type='button'] {
          background-color: #ff4d4f;
          color: white;
          border: none;
          padding: 0.4rem 0.75rem;
          font-size: 0.85rem;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease;
    
          &:hover {
            background-color: #d9363e;
          }
        }
      }
      .plus-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;               // Set width and height for a circular button
        height: 36px;
        margin: 10px auto 30px;
        // background-color: #4caf50; // Green background color for the "Add" button
        color: white;
        border: none;
        border-radius: 50%;        // Makes the button circular
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;
    
        svg {
          width: 20px;             // Adjust icon size
          height: 20px;
          fill: currentColor;      // Matches the SVG icon color to button text color
        }
    
        &:hover {
          background-color: white;
        }
 
    
        &:active {
          transform: scale(0.95);    // Slight scale-down effect when clicked
        }
      }
    }
    
  }
}
