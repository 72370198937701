@import '../../../scss/config';

.app-layout {
  // display: flex;
  min-height: 101vh;
  padding-bottom: 14px;
  @include mediaLg {
    padding: 14px 0;
  }
  @include mediaXXlg {
    padding: 32px 0;
  }
  &.in-help-page {
    padding: 0;
    // background-image: url('../../assets/imgs/settings/help-bg.png');
    background-repeat: repeat;
    .layout-content {
      display: block;
      min-height: 100vh;
    }
  }
  .layout-content {
    @include mediaLg {
      display: flex;
    }
    // gap: 10px;
    // @include mediaXXlg {
    //   gap: 18px;
    // }

    & > [class$='page'] {
      min-width: 400px;
      flex-grow: 1;
      @include mediaLg {
        margin-top: 18px;
      }
    }
  }

  .main-app-bar-wrap {
    width: 198px;
    padding: 32px 6px;
    padding-right: 0;
    height: 100vh;
 direction: ltr;
      overflow-y: auto;
    @include mediaXXlg {
      width: 260px;
      padding: 42px 6px;
      padding-right: 0;
    }
  @include custom-scrollbar;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #5e5d5d;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b2b2b2ac;
        border-radius: 10px;

        &:hover {
          background: #aaa;
        }
      }
    .wrap-content {
      display: grid;
      grid-template-rows: auto 1fr auto;
      gap: 22px;
     
 direction: rtl;
    
      padding: 0 8px;
      @include mediaXXlg {
        padding: 0 22px;
        padding-left: 22px;
      }
    }
  }

  .custom-container-wrapper {
    @include mediaLg {
      margin-right: 198px;
      width: calc(100vw - 198px - 10px);
      @include mediaXXlg {
        margin-right: 260px;
        width: calc(100vw - 260px - 10px);
      }
    }
  }
  // .custom-container-wrapper {
  //   width: calc(100vw - 198px - 10px - 10px);
  //   @include mediaXXlg {
  //     width: calc(100vw - 260px - 18px - 10px);
  //   }
  // }
}
.container-scroll{
  height: 100%;
  max-height: 80vh;
  overflow-y: auto;
  @include custom-scrollbar;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #5e5d5d;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b2b2b2ac;
    border-radius: 10px;

    &:hover {
      background: #aaa;
    }
  }
}