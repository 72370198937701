.control-btn-search-form-warpper {
  // display: grid;
  // grid-template-columns: 232px auto;
  width: 286px;
  display: flex;
  gap: 10px;
  align-items: center;

  .form-control-btn {
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    border: 0;
    outline: 0;
    background-color: rgba(209, 211, 212, 0.2);
    transform: rotate(360deg);
    transition: background-color 0.2s ease-out, transform 0.4s ease-out 0.2s;
    &.visible {
      transform: rotate(0);
      width: 44px;
      height: 44px;
    }
  }

  .table-filter-search-form {
    position: relative;
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.7s ease-out, visibility 0.7s ease-out,
      width 0.6s ease-in-out;
    &.visible {
      width: 232px;
      opacity: 1;
      visibility: visible;
    }
    input {
      width: 100%;
      height: 48px;
      background: rgba(209, 211, 212, 0.2);
      border-radius: 100px;
      border: 0;
      padding-right: 54px;
      outline: 0;
      &:focus {
        box-shadow: 0 0 2px rgba(#000, 0.4);
      }
    }

    button {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 0;
      top: 50%;
      transform: translate(-2px, -50%);
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      border: 0;
      outline: 0;
      background-color: rgba(209, 211, 212, 0.4);
    }
  }
}
