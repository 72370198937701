@import '../../scss/config';

.website-page {
  .page-title {
    font-size: 18px;
    color: rgba(64, 64, 65, 0.6);
    margin-bottom: 8px;
  }
  .website-title {
    font-size: 22px;
    color: #404041;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .page-main-content {
    .page-title {
      color: #40404199;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }

  .form-list-wrapper {
    @include mediaLg {
      display: flex;
      gap: 10px;
    }

    .forms-section {
      width: 100%;
      display: grid;
      gap: 18px;
      @include mediaXXlg {
        width: 48%;
      }
    }
    .desc-sec {
      margin-top: 22px;

      @include mediaLg {
        margin-top: 0;
        position: sticky;
        top: 32px;
        width: 100%;
        height: fit-content;
      }
      @include mediaXXlg {
        width: 50%;
      }
      border: 1px solid rgba(209, 211, 212, 0.6);
      border-radius: 12px;
      // box-shadow: 2px 2px 12px #091a5814;

      .desc-header {
        color: rgba(64, 64, 65, 0.6);
        padding: 14px 22px;
        border-bottom: 1px solid rgba(#000, 0.1);
        font-size: 16px;
      }

      .sec-list-wrapper {
        padding: 18px 12px;
        padding-left: 2px;
        // height: 60vh;
        max-height: 63vh;
        overflow-y: auto;
        display: grid;
        align-content: start;
        @include custom-scrollbar;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background-color: #e1e1e1;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #ccc;
          &:hover {
            background: #aaa;
          }
        }
        .item-item {
          display: flex;
          align-items: center;
          gap: 14px;
          border-bottom: 1px solid rgba(209, 211, 212, 0.2);
          padding: 10px;
          @include mediaXXlg {
            padding: 25px;
          }
          &:last-of-type {
            border-bottom: 0;
          }
          .item-img-wrapper {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            background-color: #eee;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 36px;
              height: 36px;
            }
            img {
              width: 32px;
              max-height: 42px;
              object-fit: cover;
              object-position: center center;
              border-radius: 2px;
            }
          }
          .item-details {
            .name {
              color: #404041;
              font-weight: bold;
              text-transform: capitalize;
              font-size: 18px;
              margin-bottom: 8px;
            }
            .model-p {
              color: #40404166;
            }
          }

          .item-btns-wrapper {
            display: flex;
            align-items: center;
            gap: 18px;
            justify-content: flex-start;
            margin-top: 10px;
            .btn {
              color: rgba(64, 64, 65, 0.6);
              border: 0;
              outline: 0;
              background-color: transparent;
              display: flex;
              align-items: center;
              gap: 4px;
              cursor: pointer;
              transition: all 0.15s ease-out;

              svg {
                width: 18px;
                path {
                  transition: all 0.15s ease-out;
                }
              }

              &:hover {
                color: #000;
                svg {
                  path {
                    fill: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
